import { stAnalytics } from "@repo/analytics";
import { BrowserStorage } from "@repo/storage";
import { Match, Switch, createSignal } from "solid-js";
import { isAuthenticatedIdentity } from "~/domains/identity/types";
import { getUserAvatarPath } from "~/lib/avatars";
import { useUIState } from "~/ui/UIState";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import { useWire } from "~/wire";
import { PrimaryCTAAnchor } from "../CTA/PrimaryCTAAnchor";
import { SecondaryCTAAnchor } from "../CTA/SecondaryCTAAnchor";
import { UserContextMenu } from "../_context-menu/UserContextMenu";
import { UserAvatar } from "./UserAvatar";
import { photoURL } from "@repo/client";
import styles from "./UserAvatarMenu.module.css";

/**
 * **UserAvatar**
 *
 * Note: Requires either `initial` **or** `imageUrl` to be provided.
 */
export const UserAvatarMenu = () => {
  const [userButtonRef, setUserButtonRef] = createSignal<HTMLElement>((<></>) as HTMLElement);
  const wire = useWire();

  const user = () => {
    if (!isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)) {
      return undefined;
    }
    return wire.services.identity.snapshot.context.identity;
  };

  const [isMobile] = useUIState().isMobile;

  return (
    <>
      <Switch>
        <Match
          when={
            !wire.services.identity.snapshot.context.identity.isGuest ||
            (wire.services.identity.snapshot.context.identity.isGuest && isMobile())
          }
        >
          <button
            ref={(ref) => setUserButtonRef(ref)}
            class={styles.userAvatarMenu}
            type="button"
            aria-controls={GLOBAL_ELEMENT_IDS.accountContextMenu}
          >
            <Switch>
              <Match when={user()?.displayName && !user()?.pictureURL.includes(photoURL.DefaultGuestPhotoFilename)}>
                <UserAvatar
                  // biome-ignore lint/style/noNonNullAssertion: <explanation>
                  name={(user()!.displayName as string) ?? (user()!.email as string)}
                  imageUrl={getUserAvatarPath(user()?.userId, user()?.pictureURL)}
                  size="menu"
                />
              </Match>
              <Match when={user()?.displayName && user()?.displayName !== "Guest"}>
                <UserAvatar
                  // biome-ignore lint/style/noNonNullAssertion: <explanation>
                  name={user()!.displayName as string}
                  // biome-ignore lint/style/noNonNullAssertion: <explanation>
                  initial={user()!.displayName!.charAt(0) ?? user()!.email.charAt(0)}
                  size="menu"
                />
              </Match>
              <Match when={user()?.email && user()?.displayName === "Guest"}>
                {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
                <UserAvatar name={user()!.email} isGuest size="menu" />
              </Match>
              <Match when={user() === undefined || user()?.displayName === "Guest"}>
                <UserAvatar name="Guest" isGuest size="menu" />
              </Match>
            </Switch>
            <span class="screen-reader">Your account menu.</span>
          </button>
          <UserContextMenu id={GLOBAL_ELEMENT_IDS.accountContextMenu} bound={userButtonRef()} placement="bottom-end" />
        </Match>
        <Match when={wire.services.identity.snapshot.context.identity.isGuest}>
          <div class={styles.userAvatarMenuGuest}>
            <SecondaryCTAAnchor
              href="/auth/login"
              label="Log in"
              accessibleSuffix="to your account."
              data-test-id="collections-login"
              onClick={() => {
                stAnalytics.track("log_in_clicked", undefined);
                BrowserStorage.setSignupReferrerUrl(location.pathname);
              }}
            />
            <PrimaryCTAAnchor
              href="/auth/signup"
              label="Sign up"
              accessibleSuffix="to your account."
              data-test-id="collections-signup"
              onClick={() => {
                stAnalytics.track("sign_up_clicked", undefined);
                BrowserStorage.setSignupReferrerUrl(location.pathname);
              }}
            />
          </div>
        </Match>
      </Switch>
    </>
  );
};
