import { classNames } from "~/lib/classNames";
import styles from "./StorytellLogo.module.css";

interface Props {
  spacing?: boolean;
}

export const StorytellLogo = (props: Props) => (
  <a class={classNames(styles.storytellLogo, props.spacing ? styles.storytellLogoSpacing : "")} href="/" target="_self">
    <span class="screen-reader">Go to homepage</span>
    <span class={styles.storytellLogoImage} role="presentation" />
  </a>
);
