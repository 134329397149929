import { type ParentProps, Show, createSignal, createUniqueId } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./SideBarSectionAccordion.module.css";

interface Props extends ParentProps {
  label: string;
  depth: number;
  initiallyOpen?: boolean;
  class?: string;
  onClick?: () => void;
}

export const SideBarSectionAccordion = (props: Props) => {
  const uniqueId = createUniqueId();
  const [toggle, setToggle] = createSignal(props.initiallyOpen ?? false);
  return (
    <section class={classNames(styles.sidebarAccordion, toggle() ? styles.sidebarAccordionOpen : undefined)}>
      <button
        type="button"
        class={styles.sidebarAccordionTrigger}
        on:click={(event: Event) => {
          setToggle(!toggle());
          if (props.onClick) {
            props.onClick();
          }
          event.preventDefault();
          event.stopImmediatePropagation();
        }}
        aria-controls={`section-accordion-${props.label.replace(/\s/g, "-").toLowerCase()}-${uniqueId}`}
        aria-expanded={toggle()}
        tabIndex={0}
      >
        <span class={styles.sidebarAccordionLabel}>{props.label}</span>
        <span class={styles.sidebarAccordionIndicator} />
      </button>
      <div role="region" id={`section-accordion-${props.label.replace(/\s/g, "-").toLowerCase()}-${uniqueId}`}>
        <Show when={toggle()}>
          <menu>{props.children}</menu>
        </Show>
      </div>
    </section>
  );
};
