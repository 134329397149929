import { A } from "@solidjs/router";
import { Show, splitProps } from "solid-js";
import { StIcon, type StIconComponent } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import { capitalize } from "~/lib/string/capitalize";
import styles from "./IconCTA.module.css";
import type { CTAProps } from "./types";

type Modifiers = "surface" | "background" | "large" | "small";
interface Props extends CTAProps {
  accessibleLabel: string;
  icon: StIconComponent;
  href: string;
  modifiers?: Modifiers[];
  newWindow?: boolean;
  target: "_blank" | "_self";
  onClick?: (event: Event) => void;
}

export const IconAnchor = (props: Props) => {
  const [local, rest] = splitProps(props, [
    "accessibleLabel",
    "icon",
    "modifiers",
    "newWindow",
    "target",
    "onClick",
    "tabIndex",
    "class",
  ]);
  return (
    <A
      {...rest}
      type="button"
      class={classNames(
        styles.iconCta,
        local.modifiers ? local.modifiers.map((modifier) => styles[`iconCta${capitalize(modifier)}`]).join(" ") : "",
        styles.iconCtaAnchor,
        local.class,
      )}
      title={local.accessibleLabel}
      tabIndex={local.tabIndex ?? 0}
      target={local.target}
      onClick={local.onClick}
    >
      <StIcon icon={local.icon} class={styles.iconCtaIcon} size="1.25rem" />
      <span class="screen-reader">{local.accessibleLabel}</span>
      <Show when={local.newWindow}>
        <span class="screen-reader">opens in a new window.</span>
      </Show>
    </A>
  );
};
