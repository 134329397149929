import { DesktopOnly } from "../_containers/DesktopOnly";
import styles from "./AlternativeSideBar.module.css";
import { SidebarChats } from "./SidebarChats";
import { SidebarCollections } from "./SidebarCollections";
import { StorytellLogo } from "./StorytellLogo";
interface Props {
  collectionId: string | undefined;
}

export const AlternativeSideBar = (props: Props) => {
  return (
    <>
      <DesktopOnly>
        <StorytellLogo spacing={true} />
      </DesktopOnly>
      <div class={styles.alternativeSidebar}>
        <SidebarCollections />
        <SidebarChats />
      </div>
    </>
  );
};
