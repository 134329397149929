import type { ParentProps } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./FloatingPromptBarContainer.module.css";

interface Props extends ParentProps {
  id?: string;
  modifier?: "fixed" | "left";
}

export const FloatingPromptBarContainer = (props: Props) => (
  <div
    id={props.id}
    class={classNames(
      styles.floatingPromptBarContainer,
      props.modifier === "fixed" ? styles.floatingPromptBarContainerFixed : "",
      props.modifier === "left" ? styles.floatingPromptBarContainerLeft : "",
    )}
  >
    {props.children}
  </div>
);
