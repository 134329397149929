import { stAnalytics } from "@repo/analytics";
import { BsQuestion } from "solid-icons/bs";
import { type JSX, onCleanup, onMount } from "solid-js";
import { classNames } from "~/lib/classNames";
import { getScreenType } from "~/lib/ui/getScreenType";
import { useUIState } from "~/ui/UIState";
import { GLOBAL_ELEMENT_ATTRIBUTES } from "~/ui/global-element-attributes";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import { IconAnchor } from "../CTA/IconAnchor";
import { BurgerButton } from "../SideBar/BurgerButton";
import { StorytellLogo } from "../SideBar/StorytellLogo";
import { UserAvatarMenu } from "../SideBar/UserAvatarMenu";
import { LeftDrawer } from "../_containers/LeftDrawer";
import mobileStyles from "./MobileHeader.module.css";
import styles from "./TwoColumnLayout.module.css";
interface Props {
  sidebar: JSX.Element;
  content: JSX.Element;
  toggleTop?: JSX.Element;
}

//  TODO - Rethink the TwoColumnLayout component and mobile navigation
export const TwoColumnLayout = (props: Props) => {
  const state = useUIState();
  const [open] = state.leftDrawer;
  const [, setIsTwoColumn] = useUIState().isTwoColumn;
  setIsTwoColumn(true);

  let container: HTMLElement | undefined;
  let callback: ReturnType<typeof requestAnimationFrame> | undefined;
  let lastPosition = 0;

  const frame = () => {
    if (container) {
      if (container.scrollTop > lastPosition) {
        document.documentElement.setAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrollDirection, "down");
      } else {
        document.documentElement.setAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrollDirection, "up");
      }
      if (container.scrollTop > 0) {
        document.documentElement.setAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrolled, "true");
      } else {
        document.documentElement.setAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrolled, "false");
      }
      lastPosition = container.scrollTop;
    }
    callback = undefined;
  };

  const onScroll = () => {
    if (!callback) {
      callback = requestAnimationFrame(frame);
    }
  };

  onMount(() => {
    if (props.toggleTop) {
      globalThis.addEventListener("scroll", onScroll);
      onCleanup(() => {
        globalThis.removeEventListener("scroll", onScroll);
        document.documentElement.removeAttribute("data-scroll");
        if (callback) {
          cancelAnimationFrame(callback);
        }
      });
    }
  });

  return (
    <div id={GLOBAL_ELEMENT_IDS.main} data-component="container" class={styles.twoColumnLayout}>
      <div class={mobileStyles.collectionsTopMobile}>
        <div class={mobileStyles.collectionsTopLeft}>
          <BurgerButton controls="my-menu-id" />
        </div>
        <div class={mobileStyles.collectionsTopMiddle}>
          <StorytellLogo />
        </div>
        <div class={mobileStyles.collectionsTopRight}>
          <IconAnchor
            class={mobileStyles.collectionsTopRightDocs}
            data-test-id="view-documentation-mobile"
            href="http://docs.storytell.ai/"
            newWindow
            accessibleLabel="View documentation"
            icon={BsQuestion}
            modifiers={["surface"]}
            target="_blank"
            onClick={() => {
              stAnalytics.track("click_tracking", {
                cta: "documentation",
                position: "header",
                screen: getScreenType(),
              });
            }}
          />
          <UserAvatarMenu />
        </div>
      </div>
      <LeftDrawer class={classNames(styles.twoColumnLayoutSidebar, open() ? styles.twoColumnLayoutSidebarOpen : "")}>
        <nav data-component="sidebar" class={styles.twoColumnLayoutSidebarContent}>
          {/* <div class={styles.twoColumnLayoutSidebarResize} /> */}
          {props.sidebar}
        </nav>
      </LeftDrawer>
      <main
        data-component="content"
        id={GLOBAL_ELEMENT_IDS.mainContent}
        tabIndex={-1}
        class={styles.twoColumnLayoutContent}
        onScroll={props.toggleTop ? onScroll : undefined}
        ref={container}
      >
        {props.content}
      </main>
    </div>
  );
};
