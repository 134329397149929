import { classNames } from "~/lib/classNames";
import { useUIState } from "~/ui/UIState";
import styles from "./BurgerButton.module.css";

interface Props {
  controls: string;
}

export const BurgerButton = (props: Props) => {
  const state = useUIState();
  const [open, setOpen] = state.leftDrawer;

  const onClick = () => {
    setOpen(!open());
  };
  return (
    <button
      type="button"
      class={classNames(styles.burgerButton, open() ? styles.burgerButtonOpen : "")}
      title="Open the main menu."
      aria-controls={props.controls}
      aria-expanded={open()}
      onClick={onClick}
    >
      <span class={classNames(styles.burgerButtonLine, styles.burgerButtonLineTop)} />
      <span class={classNames(styles.burgerButtonLine, styles.burgerButtonLineMiddle)} />
      <span class={classNames(styles.burgerButtonLine, styles.burgerButtonLineBottom)} />
      <span class="screen-reader">Open the main menu.</span>
    </button>
  );
};
