import type { ValidationMethods } from "@repo/validation";
import type { IconTypes } from "solid-icons";
import { Show } from "solid-js";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./TextArea.module.css";

interface Props {
  placeholder: string;
  label: string;
  name: string;
  icon?: IconTypes;
  required?: boolean;
  validation?: Array<keyof typeof ValidationMethods>;
}

export const TextArea = (props: Props) => {
  return (
    <div class={classNames(styles.textArea, props.icon ? styles.textAreaHasIcon : "")}>
      <Show when={props.icon}>
        {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
        <StIcon icon={props.icon!} class={styles.textAreaIcon} size="1.75rem" />
      </Show>
      <textarea
        class={styles.textAreaField}
        placeholder={props.placeholder}
        aria-label={props.label}
        name={props.name}
        required={props.required}
        data-validation={props.validation}
      />
    </div>
  );
};
