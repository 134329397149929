import { splitProps } from "solid-js";
import { StIcon, type StIconComponent } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import { capitalize } from "~/lib/string/capitalize";
import styles from "./IconCTA.module.css";
import type { CTAProps } from "./types";

type Modifiers = "surface" | "background" | "large" | "small";
interface Props extends CTAProps {
  accessibleLabel: string;
  icon: StIconComponent;
  modifiers?: Modifiers[];
}

export const IconCTA = (props: Props) => {
  const [local, rest] = splitProps(props, ["accessibleLabel", "icon", "modifiers"]);
  return (
    <button
      {...rest}
      type="button"
      class={classNames(
        styles.iconCta,
        props.class,
        local.modifiers ? local.modifiers.map((modifier) => styles[`iconCta${capitalize(modifier)}`]).join(" ") : "",
      )}
      title={local.accessibleLabel}
      tabIndex={props.tabIndex ?? 0}
    >
      <StIcon icon={local.icon} class={styles.iconCtaIcon} size="1.25rem" />
      <span class="screen-reader">{local.accessibleLabel}</span>
    </button>
  );
};
