import type { ValidationMethods } from "@repo/validation";
import type { IconTypes } from "solid-icons";
import { type Accessor, Show, createEffect, createSignal } from "solid-js";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./LargeInputText.module.css";

type Modifier = "outline" | "overlay";
interface Props {
  placeholder: string;
  label: string;
  name: string;
  icon?: IconTypes;
  required?: boolean;
  value: string;
  validation?: Array<keyof typeof ValidationMethods>;
  errorMessage?: string;
  class?: string;
  modifier?: Modifier;
  size?: "tight";
  focus?: Accessor<boolean>;
}

export const LargeInputText = (props: Props) => {
  const [inputRef, setInputRef] = createSignal<HTMLInputElement | null>(null);
  createEffect(() => {
    const input = inputRef();
    if (input && props.focus?.()) {
      input.focus();
    }
  });
  return (
    <div
      class={classNames(
        styles.largeInputText,
        props.class,
        props.modifier === "outline" ? styles.largeInputTextOutline : "",
        props.modifier === "overlay" ? styles.largeInputTextOverlay : "",
        props.size === "tight" ? styles.largeInputTextTight : "",
        props.icon ? styles.largeInputTextHasIcon : "",
        props.required ? styles.largeInputTextRequired : "",
        props.errorMessage ? styles.largeInputTextInvalid : "",
      )}
    >
      <Show when={props.icon}>
        {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
        <StIcon icon={props.icon!} class={styles.largeInputTextIcon} size="1.75rem" />
      </Show>
      <input
        type="text"
        ref={setInputRef}
        class={styles.largeInputTextField}
        placeholder={props.placeholder}
        aria-label={props.label}
        required={props.required}
        name={props.name}
        value={props.value ?? ""}
        aria-invalid={props.required && !props.value}
        data-validation={props.validation ? props.validation.join(",") : undefined}
        autocomplete="off"
        data-1p-ignore
      />
      {/* <span class={styles.largeInputTextError} aria-live="polite">
        {props.errorMessage}
      </span> */}
    </div>
  );
};
